@import "variables";

.bgc {
  &-green-base {
    background-color: $green-base;
  }

  &-green-base-opacity {
    background-color: $green-base-01;
  }

  &-green-primary {
    background-color: $green-primary;
  }

  &-white-base {
    background-color: $white-base;
  }

  &-grey-primary-opacity {
    background-color: $grey-primary-01;
  }

  &-grey-primary-100 {
    background-color: $grey-primary-04;
  }

  &-person {
    &-family {
      background-color: $person-family;
    }
    &-friend {
      background-color: $person-friend;
    }

    &-in-company {
      background-color: $person-in-company;
    }

    &-outside-company {
      background-color: $person-outside-company;
    }
  }

  &-relationship {
    &-a {
      background-color: $relationship-a;
    }

    &-b {
      background-color: $relationship-b;
    }

    &-c {
      background-color: $relationship-c;
    }

    &-d {
      background-color: $relationship-d;
    }

    &-e {
      background-color: $relationship-e;
    }
  }
}
