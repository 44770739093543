.rounded {
  &-4 {
    border-radius: 4px;
  }

  &-6 {
    border-radius: 6px;
  }

  &-10 {
    border-radius: 10px;
  }
}

.rounded-top {
  &-10 {
    border-radius: 10px 10px 0 0;
  }
}

.rounded-bottom {
  &-10 {
    border-radius: 0px 0px 10px 10px;
  }
}
