@import "variables";

.c {
  &-black-base {
    color: $black-base;
  }

  &-black-primary {
    color: $black-primary;
  }

  &-white-base {
    color: $white-base;
  }

  &-green-primary {
    color: $green-primary;
  }

  &-orange-primary {
    color: $orange-primary;
  }

  &-grey-primary {
    color: $grey-primary;
  }
  &-relationship-e {
    color: $relationship-e;
  }
}
