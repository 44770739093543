.fs {
  &-14 {
    font-size: 14px;
  }

  &-16 {
    font-size: 16px;
  }

  &-18 {
    font-size: 18px;
  }

  &-20 {
    font-size: 20px;
  }

  &-24 {
    font-size: 24px;
  }

  &-28 {
    font-size: 28px;
  }

  &-30 {
    font-size: 30px;
  }

  &-32 {
    font-size: 32px;
  }

  &-36 {
    font-size: 36px;
  }

  &-48 {
    font-size: 48px;
  }

  &-60 {
    font-size: 60px;
  }

  &-64 {
    font-size: 64px;
  }

  &-72 {
    font-size: 72px;
  }
}
