//======= Padding ======= //
.pt-200 {
  padding-top: 200px !important;
}
.p-lr5-tb30 {
  padding: 5px 30px;
}
.pl-10 {
  padding-left: 10% !important;
}

//======= Margin ======= //
.mr-150 {
  margin-right: 10% !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.my-250 {
  margin: 250px 0px !important;
}
.ms-2-em {
  margin-left: 2em !important;
}

//======= Buton ======= //
.btn-darkgey {
  background-color: #cfd8e0 !important;
}
.btn-blue-first {
  background-color: #5870f5 !important;
}
.btn-green-first {
  background-color: #06a192 !important;
}

//======= Font ======= //
.font-weitht-bold-500 {
  font-weight: 500 !important;
}

//======= Background ======= //
.bg-blue-first {
  background-color: #011a72;
}
.bg-blue-secondary {
  background-color: #626691;
}

//======= Width ======= //
.w-100 {
  width: 100px !important;
}

.w-65p {
  width: 65%;
}
//======= Form ======= //
.form-radio-button {
  .field_with_errors {
    display: inline;
  }
  input[type="radio"] {
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }
}

.timepicker-input {
  .input-group {
    .field_with_errors {
      width: 91%;
    }
  }
}
//======= Link to ======= //
.link-t10-l63 {
  position: fixed;
  top: 10%;
  left: 63px;
  font-size: 18px;
}

.link-t10-r63 {
  position: absolute;
  top: 7%;
  right: 63px;
}

.link-footer-right {
  position: fixed;
  top: 95%;
  right: 0;
  transform: translate(-50%, -50%);
}

//======= Page My page ======= //
.form-btn-mypage a {
  margin-bottom: 2em;
}

#footer-my-page {
  position: absolute;
  bottom: 10px;
  width: 50%;
}

//======= Page 404 ======= //
.page-404 {
  padding: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  position: absolute;
  height: 100%;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;

  .m-404 {
    margin-right: 60px;
  }

  .t-404 {
    color: #f44336;
    font-size: 60px;
    line-height: 1;
    font-weight: 900;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
  }
  .st-404 {
    color: #313435;
    text-transform: capitalize;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    font-weight: 500;
  }
  .d-404 {
    color: #72777a;
    font-size: 1rem;
    margin-bottom: 30px;
    line-height: 1.9;
  }
}

//======= Text ======= //
.text-underline {
  text-decoration: underline;
}

//======= Custom ======= //
.time-line div {
  padding: 2px;
  background-color: #539cdc;
  width: 5%;
}

.form-btn-mypage a {
  width: 300px;
}

.video-after-ffs {
  position: relative;
  width: 530px;
  height: 298px;
  margin: 2em auto;
  .video-after-ffs__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-after-ffs-btn {
  width: 300px;
  color: #fff;
  margin-bottom: 2em;
}

.video-after-ffs-btn:hover {
  color: #fff;
}

.mb-65 {
  margin-bottom: 65px;
}

.video-ffs-guidance {
  position: relative;
  padding-bottom: 56.25%;
  width: 530px;
  height: 298px;
  .video-ffs-guidance__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.infor_video_guidance {
  width: 510px;
}
.arlert_message_change_password {
  padding-top: 10px;
  font-size: 20px;
}

.link-privacy-policy {
  bottom: 10%;
  text-align: end;
}
.a.navbar-brand {
  font-size: 0.8rem;
}
@media (max-width: 480px) {
  .link-privacy-policy {
    text-align: center;
  }
}
