$green-base: #e4f9f5;
$green-base-01: #e5f4f1;
$green-primary: #1ca68d;
$orange-primary: #ff9624;
$orange-primary-01: #f27a09;
$black-base: #222222;
$black-primary: #444444;
$grey-primary: #888888;
$grey-primary-01: #cacaca;
$grey-primary-02: #606b73;
$grey-primary-03: #eeeeee;
$grey-primary-04: #f5f5f5;
$white-base: #ffffff;
$person-family: #90d470;
$person-friend: #ec70d1;
$person-in-company: #e15050;
$person-outside-company: #9391ec;
$relationship-a: #8d959a;
$relationship-b: #e2a3ad;
$relationship-c: #0080b5;
$relationship-d: #ead61d;
$relationship-e: #70b7d4;
