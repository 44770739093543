@import "./components/variables";

/*  ------------- Screen PC  ------------- */
/*  --------------- Common  -------------- */
.communication-tool-navbar {
  height: 110px;
  .communication-tool-navbar__content {
    display: flex;
    justify-content: end;
    .communication-tool-navbar {
      &__items {
        flex-wrap: wrap;
        display: flex;
        color: $black-primary;
        p {
          flex-shrink: 0;
        }
        &.active {
          color: $green-primary;
          .navbar-icons-original {
            background: $green-primary;
            &-self-test {
              background: $green-primary;
              mask: url("/app/javascript/images/bi_person-fill-check.svg")
                no-repeat center / contain;
              -webkit-mask: url("/app/javascript/images/bi_person-fill-check.svg")
                no-repeat center / contain;
            }
            &-with-relationship_surroundings {
              background: $green-primary;
              mask: url("../../images/bi_person-fill.svg") no-repeat center /
                contain;
              -webkit-mask: url("../../images/bi_person-fill.svg") no-repeat
                center / contain;
            }

            &-with-sophistication {
              background: $green-primary;
              mask: url("../../images/solar_square-academic-cap-bold.svg")
                no-repeat center / contain;
              -webkit-mask: url("../../images/solar_square-academic-cap-bold.svg")
                no-repeat center / contain;
            }
            &-self-characteristics {
              background: $green-primary;
              mask: url("../../images/fluent_book-star-24-filled.svg") no-repeat
                center / contain;
              -webkit-mask: url("../../images/fluent_book-star-24-filled.svg")
                no-repeat center / contain;
            }
          }
        }
      }
    }
  }
}

.navbar-icons-original {
  &-self-test {
    background: url("../../images/bi_person-fill-check.svg") no-repeat center /
      contain;
  }

  &-with-relationship_surroundings {
    background: url("../../images/bi_person-fill.svg") no-repeat center /
      contain;
  }

  &-with-sophistication {
    background: url("../../images/solar_square-academic-cap-bold.svg") no-repeat
      center / contain;
  }

  &-self-characteristics {
    background: url("../../images/fluent_book-star-24-filled.svg") no-repeat
      center / contain;
  }
}

.plus-icon {
  width: 30px;
  height: 30px;
  background-color: $green-primary;
  // position: relative;
  // .plus-horizontal,
  // .plus-vertical {
  //   position: absolute;
  // }

  // .plus-horizontal {
  //   width: 100%;
  //   height: 10px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   background: linear-gradient(
  //     to left,
  //     $orange-primary 50%,
  //     $green-primary 0%
  //   );
  // }

  // .plus-vertical {
  //   width: 10px;
  //   height: 100%;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   background-color: $green-primary;
  // }
}

.relationship-person {
  &-in-company.active {
    background-color: $person-in-company !important;
  }

  &-outside-company.active {
    background-color: $person-outside-company !important;
  }

  &-family.active {
    background-color: $person-family !important;
  }

  &-friend.active {
    background-color: $person-friend !important;
  }
}

.relationship-factor {
  &-a.active {
    background-color: $relationship-a !important;
    .relationship-types {
      color: $relationship-a !important;
    }
  }

  &-b.active {
    background-color: $relationship-b !important;
    .relationship-types {
      color: $relationship-b !important;
    }
  }

  &-c.active {
    background-color: $relationship-c !important;
    .relationship-types {
      color: $relationship-c !important;
    }
  }

  &-d.active {
    background-color: $relationship-d !important;
    .relationship-types {
      color: $relationship-d !important;
    }
  }

  &-e.active {
    background-color: $relationship-e !important;
    .relationship-types {
      color: $relationship-e !important;
    }
  }
}

.sophistications-list {
  .sophistications-items {
    .sophistications-items-title {
      height: 100px;
      display: flex;
      justify-content: center;
      z-index: 1;
      &__img {
        left: 0;
        position: absolute;
        margin-top: -20px;
        width: 140px;
        height: 120px;
        flex-shrink: 0;
      }
      .sophistications-items-title-img {
        &__a {
          &-0 {
            background: url("../../images/A-0.svg") no-repeat center / contain;
          }
          &-1 {
            background: url("../../images/A-1.svg") no-repeat center / contain;
          }
          &-2 {
            background: url("../../images/A-2.svg") no-repeat center / contain;
          }
          &-3 {
            background: url("../../images/A-3.svg") no-repeat center / contain;
          }
          &-4 {
            background: url("../../images/A-4.svg") no-repeat center / contain;
          }
        }
        &__b {
          &-0 {
            background: url("../../images/B-0.svg") no-repeat center / contain;
          }
          &-1 {
            width: 150px;
            background: url("../../images/B-1.svg") no-repeat center / contain;
          }
          &-2 {
            background: url("../../images/B-2.svg") no-repeat center / contain;
          }
          &-3 {
            background: url("../../images/B-3.svg") no-repeat center / contain;
          }
          &-4 {
            width: 175px;
            background: url("../../images/B-4.svg") no-repeat center / contain;
          }
        }
        &__c {
          &-0 {
            background: url("../../images/C-0.svg") no-repeat center / contain;
          }
          &-1 {
            background: url("../../images/C-1.svg") no-repeat center / contain;
          }
          &-2 {
            background: url("../../images/C-2.svg") no-repeat center / contain;
          }
          &-3 {
            background: url("../../images/C-3.svg") no-repeat center / contain;
          }
          &-4 {
            background: url("../../images/C-4.svg") no-repeat center / contain;
          }
        }
        &__d {
          &-0 {
            background: url("../../images/D-0.svg") no-repeat center / contain;
          }
          &-1 {
            background: url("../../images/D-1.svg") no-repeat center / contain;
          }
          &-2 {
            background: url("../../images/D-2.svg") no-repeat center / contain;
          }
          &-3 {
            background: url("../../images/D-3.svg") no-repeat center / contain;
          }
          &-4 {
            background: url("../../images/D-4.svg") no-repeat center / contain;
          }
        }
        &__e {
          &-0 {
            background: url("../../images/E-0.svg") no-repeat center / contain;
          }
          &-1 {
            background: url("../../images/E-1.svg") no-repeat center / contain;
          }
          &-2 {
            background: url("../../images/E-2.svg") no-repeat center / contain;
          }
          &-3 {
            background: url("../../images/E-3.svg") no-repeat center / contain;
          }
          &-4 {
            width: 160px !important;
            background: url("../../images/E-4.svg") no-repeat center / contain;
          }
        }
      }

      &__description {
        padding-left: 120px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        color: $white-base;
        font-weight: 700;
        h3 {
          margin-bottom: initial;
          font-size: 24px;
        }
        h5 {
          margin-bottom: initial;
          font-size: 18px;
        }
      }
      &__line {
        &-middle {
          z-index: -1;
          top: 60%;
          width: 100%;
          border-color: $white-base !important;
        }
      }
    }
  }
}

.personal-characteristics {
  .personal-characteristics-btn {
    position: relative;
    &__img {
      position: absolute;
      left: 30px;
      width: 40px;
      height: 40px;
      background: url(../../images/icon-report.svg), no-repeat center / contain;
    }
    &__text {
      font-size: 20px;
    }
  }
  .personal-characteristics-box {
    border-radius: 10px;
    &__descriptions {
      li::marker {
        color: $green-primary;
      }
    }
  }
  .personal-characteristics-line {
    font-size: 45px;
    color: $green-primary;
    letter-spacing: 5px;
  }
}

.report-ffs {
  font-size: 16px;
  color: $black-base;
  .report-ffs-title {
    h3 {
      font-weight: 400;
      font-size: 16px;
    }
    &__text {
      display: flex;
      align-items: baseline;
      h1 {
        font-size: 32px;
        font-weight: 700;
        margin-right: 20px;
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .report-ffs-chart {
    &__body {
      height: 250px;
    }
    &__title {
      font-size: 16px;
    }
  }
  .report-ffs-list-items {
    .report-ffs-items {
      ul {
        margin-left: -15px;
        li::marker {
          color: $green-primary;
        }
      }

      p {
        margin-bottom: initial;
      }
    }

    .report-ffs-items + .report-ffs-items {
      margin-top: 20px;
    }
  }
}

.communication-tool-container {
  width: 65%;
  .communication-tool-btn {
    .btn__primary {
      width: 100%;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

// Refactor
.communication-tool-title {
  .communication-tool-title__icon {
    width: 30px;
    height: 30px;
    background-color: $green-primary;
    margin-right: 20px;
    // position: relative;
    // .communication-tool-title__icon-horizontal,
    // .communication-tool-title__icon-vertical {
    //   position: absolute;
    // }

    // .communication-tool-title__icon-horizontal {
    //   width: 100%;
    //   height: 10px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   background: linear-gradient(
    //     to left,
    //     $orange-primary 50%,
    //     $green-primary 0%
    //   );
    // }

    // .communication-tool-title__icon-vertical {
    //   width: 10px;
    //   height: 100%;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   background-color: $green-primary;
    // }
  }

  .communication-tool-title__text {
    font-size: 32px;
    font-weight: 700;
    color: $black-base;
  }
}

.bg-sophistication {
  &__a {
    &-0 {
      background: url("../../images/A-0.svg") no-repeat center / contain;
    }
    &-1 {
      background: url("../../images/A-1.svg") no-repeat center / contain;
    }
    &-2 {
      background: url("../../images/A-2.svg") no-repeat center / contain;
    }
    &-3 {
      background: url("../../images/A-3.svg") no-repeat center / contain;
    }
    &-4 {
      background: url("../../images/A-4.svg") no-repeat center / contain;
    }
  }
  &__b {
    &-0 {
      background: url("../../images/B-0.svg") no-repeat center / contain;
    }
    &-1 {
      background: url("../../images/B-1.svg") no-repeat center / contain;
    }
    &-2 {
      background: url("../../images/B-2.svg") no-repeat center / contain;
    }
    &-3 {
      background: url("../../images/B-3.svg") no-repeat center / contain;
    }
    &-4 {
      width: 160px !important;
      background: url("../../images/B-4.svg") no-repeat center / contain;
    }
  }
  &__c {
    &-0 {
      background: url("../../images/C-0.svg") no-repeat center / contain;
    }
    &-1 {
      background: url("../../images/C-1.svg") no-repeat center / contain;
    }
    &-2 {
      background: url("../../images/C-2.svg") no-repeat center / contain;
    }
    &-3 {
      background: url("../../images/C-3.svg") no-repeat center / contain;
    }
    &-4 {
      background: url("../../images/C-4.svg") no-repeat center / contain;
    }
  }
  &__d {
    &-0 {
      background: url("../../images/D-0.svg") no-repeat center / contain;
    }
    &-1 {
      background: url("../../images/D-1.svg") no-repeat center / contain;
    }
    &-2 {
      background: url("../../images/D-2.svg") no-repeat center / contain;
    }
    &-3 {
      background: url("../../images/D-3.svg") no-repeat center / contain;
    }
    &-4 {
      background: url("../../images/D-4.svg") no-repeat center / contain;
    }
  }
  &__e {
    &-0 {
      background: url("../../images/E-0.svg") no-repeat center / contain;
    }
    &-1 {
      background: url("../../images/E-1.svg") no-repeat center / contain;
    }
    &-2 {
      background: url("../../images/E-2.svg") no-repeat center / contain;
    }
    &-3 {
      background: url("../../images/E-3.svg") no-repeat center / contain;
    }
    &-4 {
      width: 160px !important;
      background: url("../../images/E-4.svg") no-repeat center / contain;
    }
  }
}

/*  ------------- Modal Communication tool ------------- */
#remove-relationships {
  width: 640px;
  border-radius: 10px;
  #remove-relationships__content {
    width: 640px;
    .btn-orange-base {
      border: 2px solid $orange-primary-01;
    }
    .btn-orange-base:hover {
      color: $white-base;
    }
  }
}

/*  ------------- Communication tool  ------------- */
.onboarding-content {
  h3 {
    letter-spacing: 2px;
  }
  .onboarding-content-btns {
    img {
      left: 20px;
    }
  }
}

.self-test-content {
  span.self-test-questions-number {
    font-size: 20px;
    color: $green-primary;
  }
}

.self-test__list {
  .self-test__items {
    width: 640px;
    height: 220px;
    flex-shrink: 0;
    background-color: $white-base;
    border-radius: 10px;
    &-title {
      background-color: $green-primary;
      border-radius: 10px 0px;
      width: 120px;
      padding: 4px 30px;
      font-weight: 700;
      font-size: 16px;
      color: $white-base;
    }

    &-questions {
      font-weight: 400;
      font-size: 18px;
      color: $black-base;
      text-align: center;
    }

    &-answers {
      border-radius: 6px;
      font-weight: 400;
      border: 1px solid $grey-primary-01;
    }

    &-answers:hover {
      background-color: $orange-primary;
    }

    &-answers.active {
      background-color: $orange-primary;
      border: 1px solid $orange-primary-01;
    }
  }
  .self-test__items + .self-test__items {
    margin-top: 40px;
  }
}

#resutl-content {
  &__quotes {
    width: 300px;
    height: 43px;
  }
  &__polygon img {
    width: 20px;
    height: 20px;
    bottom: -18px;
    left: calc(50% - 7px);
  }

  &__date {
    position: relative;
  }
  &__date::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 1px;
    width: 40px;
    height: 2px;
    background-color: $green-primary;
    left: 0;
    top: 50%;
  }

  &__end {
    border-radius: 10px;
  }
}

#relationship-surrounding-content {
  #relationship-surrounding-content__list {
    .relationship-surrounding-content__items {
      .btn-orange-base {
        width: 480px;
        height: 100px;
        align-items: center;
        display: flex;
        justify-content: center;
        box-shadow: 0px 8px $orange-primary-01;
        p {
          margin: auto 0;
        }
      }
      .relationship-surrounding-content__icons {
        left: 0;
        width: 40px;
        height: 40px;
      }
    }
  }
}

#registered-relationship-content {
  #registered-relationship__list {
    .registered-relationship__items {
      background-color: $white-base;
      width: 350px;
      height: 60px;
      border-radius: 6px;
      &-relationships {
        width: 20px;
        height: 20px;
      }
      &-action {
        cursor: pointer;
      }
      &-family {
        background-color: $person-family;
      }

      &-outside-company {
        background-color: $person-outside-company;
      }

      &-friend {
        background-color: $person-friend;
      }

      &-in-company {
        background-color: $person-in-company;
      }
    }
  }
}

#relationship-details-content {
  #relationship-details__title {
    height: 160px;
    border-radius: 10px;
    .relationship-details__type {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      span.relationship-types {
        font-size: 24px;
      }

      span.relationship-types + span {
        font-size: 18px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}

// .relationship-types-a {
//   color: $relationship-a;
// }
// .relationship-types-b {
//   color: $relationship-b !important;
// }
// .relationship-types-c {
//   color: $relationship-c;
// }
// .relationship-types-d {
//   color: $relationship-d;
// }
// .relationship-types-e {
//   color: $relationship-e;
// }

.relationship-types {
  display: flex;
  width: 26px;
  height: 26px;
  padding: 2px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: $white-base;
  margin: auto 0 auto 6px;

  // &__condensability {
  //   background-color: $relationship-condensability;
  // }
  // &__receptivity {
  //   background-color: $relationship-receptivity;
  // }
  // &__distinguishability {
  //   background-color: $relationship-distinguishability;
  // }
  // &__diffusivity {
  //   background-color: $relationship-diffusivity;
  // }
  // &__maintainability {
  //   background-color: $relationship-maintainability;
  // }
}
#relationship-details-content {
  #relationship-details__body {
    justify-content: space-between;
    .relationship-details__body-lelf,
    .relationship-details__body-right {
      width: 48.5%;
      background-color: white;
      border-radius: 10px;
    }
  }
}

.relationship-circle {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  color: $white-base;
  &__type {
    margin-bottom: 38px;
  }
  &__name {
    left: 30px;
    bottom: 15px;
    position: absolute;
  }
  &-text-shadow {
    &-grey {
      text-shadow: 2px 0px 0px $grey-primary-02;
    }
  }
}

#relationship-details-edit-content {
  #relationship-details-edit-body {
    .relationship-details-name {
      &__input {
        border-radius: 6px;
        border: 0;
        box-shadow: none;
      }
    }
    .relationship-details-person {
      &__list {
        border-radius: 6px;
        label.form-check-label {
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
      .form-check {
        .form-check-input {
          margin-top: initial;
          width: 36px;
          height: 36px;
          margin-right: 15px;
        }
        .form-check-label {
          background-color: $grey-primary-01;
          .relationship-types {
            color: $grey-primary-01;
          }
        }
        .form-check-input:checked {
          background-color: $white-base;
          border-color: $green-primary;
        }
        .form-check-input:checked[type="radio"] {
          background-image: url("../../images/sp_radio_green_primary.svg");
        }
      }

      .relationship-details__type {
        width: 120px;
        height: 36px;
        border-radius: 4px;
        background-color: grey;
      }
    }
    .relationship-details-factor {
      &__list {
        margin-left: auto;
        margin-right: auto;
        border-radius: 6px;
      }
      .relationship-details__type {
        width: 120px;
        height: 36px;
        border-radius: 4px;
        background-color: $green-base;
      }
    }
  }
  #relationship-details-edit__submit {
    .btn-orange-base {
      width: 320px;
      height: 76px;
      border: 2px solid $orange-primary-01;
    }
    .btn-orange-base:hover {
      color: $white-base;
    }
  }
}

#relationship-details-confirm-content {
  #relationship-details-confirm-boby {
    .relationship-details-confirm-box {
      margin-bottom: 20px;
      border-radius: 10px;
      p {
        margin-bottom: initial;
        font-size: 16px;
      }
      &__second,
      &__third,
      &__sixth,
      &__seventh,
      &__eighth,
      &__ninth,
      &__tenth {
        p {
          display: list-item;
        }
        p::marker {
          height: 6px;
          width: 6px;
          color: $green-primary;
        }
      }
    }
  }
  .btn-orange-base {
    align-items: center;
    width: 372px;
    margin-left: auto;
    margin-right: auto;
    height: 76px;
  }
  .btn-orange-base:hover {
    color: $white-base;
  }
}

#notes-content {
  #notes__body {
    textarea {
      padding: 20px;
      border-radius: 10px;
      height: 440px;
      background-color: $white-base;
      border: 1px solid $white-base;
    }
    ::-webkit-scrollbar {
      width: 3px;
      color: $black-primary;
    }

    ::-webkit-scrollbar-track {
      background: $white-base;
    }

    ::-webkit-scrollbar-thumb {
      background: $green-primary;
    }
  }
  .btn.btn-orange-base {
    // width: 320px;
    height: 76px;
    margin-left: 280px;
    margin-right: 280px;
  }
}

#factor-content {
  #factor-top {
    #factor-nav {
      width: 480px;
      margin: auto;
      border-radius: 10px;
      padding-top: 20px;
      #factor-tab {
        justify-content: space-evenly;
        width: 60%;
        z-index: 2;
        .factor-progress-bar {
          top: 45%;
          height: 2px;
          position: absolute;
          width: 80%;
          z-index: -1;
        }
      }
    }
    #factor-tab-content__title {
      .factor-tab__title-first {
        font-size: 18px;
        margin-right: 30px;
        width: 90px;
        background-color: $white-base;
        height: 32px;
        border-radius: 20px;
        color: $green-primary;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .factor-tab__title-second {
        font-size: 36px;
      }
      .factor-tab__title-description {
        margin-top: 40px;
        font-size: 16px;
        margin-bottom: 110px;
      }
    }

    .tab-step-number.active,
    .tab-step-number {
      width: 24px;
      height: 24px;
      background-color: $grey-primary-01;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
    }
    .tab-step-number.active {
      background-color: $green-primary;
      span {
        background-color: $green-primary !important;
      }
    }
  }
  #factor-tab-content {
    width: 880px;
    margin-top: -60px;
    background-color: $white-base;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    .form-check {
      color: $black-base;
      padding-top: 40px;
      padding-bottom: 40px;
      margin-left: 40px;
      display: flex;
      align-items: center;
      margin-right: 15px;
      .form-check-input {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        margin-right: 25px;
      }
      .form-check-label {
        display: flex;
        p {
          margin-bottom: initial;
          color: $black-base;
        }
      }
      .form-check-input:checked {
        background-color: $white-base;
        border-color: $green-primary;
      }
      .form-check-input:checked[type="radio"] {
        background-image: url("../../images/sp_radio_green_primary.svg");
      }
    }
    .form-bar {
      margin: 0 auto;
      width: 90%;
      border-top: 1px solid $grey-primary-03;
    }
  }
  .btn-orange-base {
    font-size: 18px;
    font-weight: 700;
    width: 320px;
    height: 76px;
    flex-shrink: 0;
    border: 2px solid $orange-primary-01;
    span {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-orange-base:hover {
    color: $white-base;
  }
  #submitBtn {
    display: none;
  }
  #prevBtn {
    display: none;
  }
  #factor-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#factor-done-content {
  #factor-done-title {
    font-size: 36px;
    padding: 100px 0 140px 0;
  }
  #factor-done-box {
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    margin-top: -80px;
    .factor-done-box-title__ellipsis::after {
      content: "...";
      top: -25%;
      position: absolute;
    }
    .factor-done-box-title__left {
      top: 50%;
      left: -40px;
      position: absolute;
      width: 16px;
      height: 2px;
      transform: rotate(60deg);
      flex-shrink: 0;
      display: inline-block;
      background-color: $black-base;
    }

    .factor-done-box-title__right {
      top: 50%;
      right: -45px;
      position: absolute;
      width: 16px;
      height: 2px;
      transform: rotate(-60deg);
      flex-shrink: 0;
      display: inline-block;
      background-color: $black-base;
    }
    .relationship-circle {
      width: 200px;
      height: 200px;
      font-size: 110px;
      &__name {
        font-size: 28px;
        left: 30%;
        bottom: 15%;
      }
    }
    .factor-done-box__link {
      border-bottom: 1px solid $grey-primary-01;
    }
  }
  #factor-done-btn {
    flex-direction: column;
    .btn-orange-base {
      width: 320px !important;
      height: 74px !important;
      border: 2px solid $orange-primary-01;
    }
  }
}

#sophistications-content {
  #sophistications-content-title {
    font-size: 32px;
  }
  #sophistications-descriptions {
    height: 160px;
    .sophistications-descriptions-img {
      margin-top: -20px;
      z-index: 2;
      position: absolute;
      height: 180px;
      width: 180px;
      left: 10%;
      &__a {
        &-0 {
          width: 230px;
          background: url("../../images/A-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/A-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/A-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/A-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/A-4.svg") no-repeat center / contain;
        }
      }
      &__b {
        &-0 {
          background: url("../../images/B-0.svg") no-repeat center / contain;
        }
        &-1 {
          width: 230px;
          background: url("../../images/B-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/B-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/B-3.svg") no-repeat center / contain;
        }
        &-4 {
          width: 260px;
          background: url("../../images/B-4.svg") no-repeat center / contain;
        }
      }
      &__c {
        &-0 {
          background: url("../../images/C-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/C-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/C-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/C-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/C-4.svg") no-repeat center / contain;
        }
      }

      &__d {
        &-0 {
          background: url("../../images/D-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/D-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/D-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/D-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/D-4.svg") no-repeat center / contain;
        }
      }
      &__e {
        &-0 {
          background: url("../../images/E-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/E-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/E-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/E-3.svg") no-repeat center / contain;
        }
        &-4 {
          width: 230px;
          background: url("../../images/E-4.svg") no-repeat center / contain;
        }
      }
    }
    .border-2 {
      z-index: 1;
      top: 65%;
      border-top: 2px solid $white-base;
      width: 100%;
    }
    .sophistications-descriptions-end-first {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 36px;
    }
    .sophistications-descriptions-end-second {
      margin-top: 20px;
      font-size: 24px;
    }
  }
  #sophistications-boby {
    margin-top: 40px;
    .check-box-original {
      margin-right: 20px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      background: url("../../images/sophistications-checkbox-active.svg")
        no-repeat center / contain;
    }
    .check-box-original.active {
      background: $orange-primary;
      mask: url("../../images/sophistications-checkbox-active.svg") no-repeat
        center / contain;
      -webkit-mask: url("../../images/sophistications-checkbox-active.svg")
        no-repeat center / contain;
    }
    .sophistications-boby-box {
      height: 110px;
      flex-shrink: 0;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  #sophistications-btn {
    display: flex;
    justify-content: center;
  }
}

#sophistications-check-content {
  #sophistications-check-boby {
    border-radius: 10px;
    #sophistications-check-title {
      font-weight: 700;
      .sophistications-check-title-first {
        span {
          padding: 3px 22px;
          border-radius: 60px;
          background-color: $green-primary;
          font-size: 18px;
        }
      }
      .sophistications-check-title-second {
        font-size: 32px;
      }
      .sophistications-check-title-third {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  #sophistications-check-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn.btn-orange-base {
      border: 2px solid $orange-primary-01;
      width: 320px;
      height: 76px;
    }
  }
}
#personal-notes-content {
  #personal-notes__body {
    textarea {
      padding: 20px;
      border-radius: 10px;
      height: 440px;
      background-color: $white-base;
      border: 1px solid $white-base;
    }
    ::-webkit-scrollbar {
      width: 3px;
      color: $black-primary;
    }

    ::-webkit-scrollbar-track {
      background: $white-base;
    }

    ::-webkit-scrollbar-thumb {
      background: $green-primary;
    }
  }
  .btn.btn-orange-base {
    height: 76px;
    margin-left: 280px;
    margin-right: 280px;
  }
}

#sophistications-profile {
  .sophistications-profile-title {
    font-size: 32px;
  }
  .sophistications-profile-descriptions {
    height: 160px;
    .sophistications-profile-descriptions-img {
      margin-top: -20px;
      z-index: 2;
      position: absolute;
      height: 180px;
      left: 10%;
      width: 180px;
      &__a {
        &-0 {
          width: 240px !important;
          background: url("../../images/A-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/A-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/A-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/A-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/A-4.svg") no-repeat center / contain;
        }
      }
      &__b {
        &-0 {
          background: url("../../images/B-0.svg") no-repeat center / contain;
        }
        &-1 {
          width: 230px;
          background: url("../../images/B-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/B-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/B-3.svg") no-repeat center / contain;
        }
        &-4 {
          width: 260px;
          background: url("../../images/B-4.svg") no-repeat center / contain;
        }
      }
      &__c {
        &-0 {
          background: url("../../images/C-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/C-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/C-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/C-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/C-4.svg") no-repeat center / contain;
        }
      }
      &__d {
        &-0 {
          background: url("../../images/D-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/D-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/D-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/D-3.svg") no-repeat center / contain;
        }
        &-4 {
          background: url("../../images/D-4.svg") no-repeat center / contain;
        }
      }
      &__e {
        &-0 {
          background: url("../../images/E-0.svg") no-repeat center / contain;
        }
        &-1 {
          background: url("../../images/E-1.svg") no-repeat center / contain;
        }
        &-2 {
          background: url("../../images/E-2.svg") no-repeat center / contain;
        }
        &-3 {
          background: url("../../images/E-3.svg") no-repeat center / contain;
        }
        &-4 {
          width: 230px;
          background: url("../../images/E-4.svg") no-repeat center / contain;
        }
      }
    }
    .border-2 {
      z-index: 1;
      top: 65%;
      border-top: 2px solid $white-base;
      width: 100%;
    }
    .sophistications-profile-descriptions-first {
      font-size: 36px;
    }
    .sophistications-profile-descriptions-second {
      font-size: 24px;
    }
  }
  .sophistications-profile-btn {
    display: flex;
    justify-content: center;
  }

  .sophistications-profile-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
  }
}

/*  ------------- Sceen SP  ------------- */
@media (max-width: 480px) {
  /*  ------------- Common  ------------- */
  .communication-tool-navbar {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 100px;
    z-index: 999;
    &__content {
      width: 100%;
      font-size: 11px;
      justify-content: space-between !important;
      div {
        flex-direction: column;
      }
      .communication-tool-navbar {
        &__items {
          flex-direction: column;
          div {
            margin-right: initial !important;
          }
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
  #mobile-header p {
    position: relative;
  }

  #mobile-header p::before,
  #mobile-header p::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 1px;
    width: calc(14% - 1.5px);
    height: 1px;
    background-color: white;
  }

  #mobile-header p::before {
    left: 0;
    top: 50%;
  }

  #mobile-header p::after {
    right: 0;
    top: 50%;
  }

  .plus-icon {
    width: 16px;
    height: 16px;
    // .plus-horizontal {
    //   height: 6px;
    // }
    // .plus-vertical {
    //   width: 6px;
    // }
  }

  /*  ------------- Communication tool  ------------- */
  .onboarding-content {
    width: 100%;
    h1 {
      font-size: 43px;
    }
    h3 {
      font-size: 24px;
    }
    .onboarding-content-btns {
      img {
        left: initial;
        width: 30px;
        top: 10px;
      }
      p {
        font-size: 18px;
      }
    }
  }

  .self-test__list {
    .self-test__items {
      width: 350px;
      height: 170px;
      &-title {
        width: 100px;
        padding: 3px 20px;
        font-size: 14px;
      }

      &-questions {
        text-align: start;
        margin-left: 10px;
      }
    }
  }

  .self-test-content {
    span.self-test-questions-number {
      font-size: 14px;
    }
  }
  #resutl-content {
    width: 100%;
    &__title span {
      font-size: 20px;
    }
    &__quotes {
      font-size: 18px;
      width: 248px;
      height: 39px;
    }
    &__polygon img {
      bottom: -14px;
      left: calc(50% - 7px);
      width: 16px;
      height: 16px;
    }
    &__date {
      font-size: 14px;
      padding-left: 30px;
    }
    &__date::after {
      width: 20px;
    }

    &__middle {
      font-size: 36px;
      text-align: center;
    }

    &__end {
      text-align: start !important;
      padding: 20px;
    }
  }

  #relationship-surrounding-content {
    width: 100%;
    &__title span {
      font-size: 20px;
    }
    .relationship-surrounding-content__icons {
      top: 15px;
      left: initial !important;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .relationship-surrounding-content__items {
      .btn-orange-base {
        width: 300px !important;
        height: 100px !important;
      }
      p {
        margin: auto 0 0 0 !important;
        font-size: 18px;
      }
    }
  }

  #registered-relationship-content {
    width: 100%;
    #registered-relationship__title span {
      font-size: 20px;
    }
    #registered-relationship__list {
      justify-content: center !important;
    }
  }

  #remove-relationships {
    #remove-relationships__content {
      width: 100%;
      #remove-relationships__title {
        font-size: 20px;
      }
      #remove-relationships__description {
        text-align: center;
      }
      #remove-relationships__submit {
        font-size: 16px;
      }
    }
  }

  #relationship-details-content {
    width: 100%;
    #relationship-details__title {
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: space-evenly !important;
      width: 100%;
      font-size: 24px;
      span.relationship-types {
        font-size: 18px;
      }
      span.relationship-types + span {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
    #relationship-details__body {
      flex-direction: column;
      width: 100%;
      .relationship-details__body-lelf,
      .relationship-details__body-right {
        width: 90%;
        p.c-black-primary,
        span.c-black-primary {
          font-size: 14px;
        }
      }
      img {
        width: 45px;
      }
      .relationship-circle {
        width: 90px;
        height: 90px;
        .relationship-circle__type {
          margin-bottom: 28px;
          font-size: 48px;
        }
        .relationship-circle__name {
          font-size: 14px;
          left: 25px;
        }
      }
    }
    #relationship-details__btn {
      flex-direction: column;
      align-items: center;
    }
  }

  #relationship-details-edit-content {
    width: 100%;
    #registered-relationship-edit {
      &__title span {
        font-size: 20px;
      }
    }

    #relationship-details-edit-body {
      p {
        font-size: 16px;
      }
      .relationship-details-person {
        &__list {
          flex-wrap: wrap;
          label.form-check-label.relationship-details__type {
            width: 90px !important;
            height: 32px !important;
          }
        }
        .form-check .form-check-input {
          width: 32px !important;
          height: 32px !important;
        }
      }
      .relationship-details-factor .relationship-details__type {
        width: 90px !important;
        height: 32px !important;
      }
      .relationship-details-factor {
        &__list {
          .form-check {
            label span.c-white-base {
              font-size: 16px;
            }
          }
        }
      }
    }
    #relationship-details-edit__submit {
      .btn-orange-base {
        width: 240px !important;
        height: 64px !important;
      }
    }
  }
  #relationship-details-confirm-content {
    width: 100% !important;
    #relationship-details-confirm-boby {
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
      .relationship-details-confirm-box {
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        p {
          margin-bottom: initial;
          font-size: 14px;
        }
        &__second,
        &__third,
        &__sixth,
        &__seventh,
        &__eighth,
        &__ninth,
        &__tenth {
          p {
            display: list-item;
          }
          p::marker {
            height: 6px;
            width: 6px;
            color: $green-primary;
          }
        }
      }
    }
    .btn-orange-base {
      align-items: center;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
      height: 64px;
    }
    .btn-orange-base:hover {
      color: $white-base;
    }
  }
  #notes-content {
    width: 100%;
    #notes__title {
      span {
        font-size: 20px;
      }
    }
    .btn.btn-orange-base {
      margin-left: 75px;
      margin-right: 75px;
      height: 64px;
    }
  }
  #factor-content {
    #factor-top {
      #factor-nav {
        width: 280px;
        justify-content: center !important;
        flex-direction: column;
        border-radius: 10px;
        p.c-green-primary {
          font-size: 12px;
        }
        #factor-tab {
          display: flex;
          z-index: 2;
          justify-content: space-between;
          width: 80%;
          .factor-progress-bar {
            top: 25%;
            width: 100%;
          }
        }
      }
      #factor-tab-content__title {
        .factor-tab__title-first {
          font-size: 14px;
          margin-right: 10px;
          width: 72px;
          height: 24px;
        }
        .factor-tab__title-second {
          font-size: 24px;
        }
        .factor-tab__title-description {
          font-size: 14px;
          margin-top: 30px;
          margin-bottom: 70px;
        }
      }

      .tab-step-number.active,
      .tab-step-number {
        width: 18px;
        height: 18px;
        background-color: $grey-primary-01;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
      }
      .tab-step-number.active {
        background-color: $green-primary;
        span {
          background-color: $green-primary !important;
        }
      }
    }
    #factor-tab-content {
      width: 350px;
      .form-check {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: 15px;
        .form-check-input {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .form-check-label {
          display: inline-block;
        }
      }
    }
    .btn-orange-base {
      font-size: 16px;
      width: 240px;
      height: 64px;
    }
    #factor-tab-content {
      margin-top: -40px;
    }
    #factor-btn {
      flex-direction: column-reverse;
    }
  }
  #factor-done-content {
    #factor-done-title {
      font-size: 24px;
      padding: 30px 0 85px 0;
    }
    #factor-done-box {
      font-size: 16px;
      margin-top: -40px;
      .factor-done-box-title__left {
        left: -25px;
      }
      .factor-done-box-title__right {
        right: -30px;
      }
      .relationship-circle {
        width: 130px;
        height: 130px;
        font-size: 72px;
        &__name {
          font-size: 16px;
        }
      }
    }
    #factor-done-btn {
      .btn-orange-base {
        width: 240px !important;
        height: 64px !important;
      }
      .link-secondary {
        font-size: 16px;
      }
    }
  }
  #sophistications-content {
    width: 100% !important;
    #sophistications-content-title {
      font-size: 24px;
    }
    #sophistications-descriptions {
      height: 100px;
      .sophistications-descriptions-end-first {
        font-size: 22px;
        margin-top: 15px;
        margin-bottom: 0px;
      }
      .sophistications-descriptions-end-second {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: -5px;
      }
      .sophistications-descriptions-img {
        height: 120px;
        left: -10%;
        width: 180px;
      }
      .border-2 {
        top: 70%;
      }

      .sophistications-boby-box {
        height: 70px;
      }
    }
    #sophistications-boby {
      margin-left: 20px;
      margin-right: 20px;
      .sophistications-boby-box {
        height: 70px !important;
      }
      .check-box-original {
        width: 20px;
        height: 20px;
      }
    }
    #sophistications-btn {
      flex-direction: column;
      align-items: center;
    }
  }
  #sophistications-check-content {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    #sophistications-check-boby {
      #sophistications-check-title {
        font-weight: 700;
        .sophistications-check-title-first {
          span {
            padding: 3px 22px;
            border-radius: 60px;
            background-color: $green-primary;
            font-size: 16px;
          }
        }
        .sophistications-check-title-second {
          font-size: 24px;
        }
        .sophistications-check-title-third {
          text-align: start;
        }
      }
    }
    #sophistications-check-btn {
      flex-direction: column-reverse;
      .btn.btn-orange-base {
        width: 240px;
        height: 64px;
      }
    }
  }
  #personal-notes-content {
    width: 100%;
    #personal-notes__title {
      span {
        font-size: 20px;
      }
    }
    .btn.btn-orange-base {
      margin-left: 75px;
      margin-right: 75px;
      height: 64px;
    }
  }
  #sophistications-profile {
    width: 100% !important;
    .sophistications-profile-title {
      font-size: 24px;
    }
    .sophistications-profile-descriptions {
      height: 100px;
      .sophistications-profile-descriptions-first {
        font-size: 24px;
      }
      .sophistications-profile-descriptions-second {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: -5px;
      }
      .sophistications-profile-descriptions-img {
        left: -10%;
        height: 120px !important;
        &__a {
          left: 5%;
          width: 120px !important;
        }
        &__b {
          left: -5%;
          width: 150px !important;
        }
        &__c {
          left: 5%;
          width: 120px !important;
        }
        &__d {
          left: 5%;
          width: 120px !important;
        }
        &__e {
          left: 5%;
          width: 120px !important;
        }
      }
      .border-2 {
        top: 70%;
      }
    }
    .sophistications-profile-btn {
      flex-direction: column-reverse;
      align-items: center;
    }
    .sophistications-profile-body {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .sophistications-list {
    .sophistications-items {
      .sophistications-items-title {
        &__description {
          h3 {
            margin-top: 15px;
            font-size: 18px;
          }
          h5 {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .personal-characteristics {
    .personal-characteristics-btn {
      flex-direction: column;
      &__text {
        font-size: 18px;
      }

      &__img {
        position: initial;
      }
    }
    .personal-characteristics-box {
      margin: 0;
      &__descriptions {
        ul {
          font-size: 14px;
          margin-left: -10px;
        }
      }
    }
  }

  .report-ffs {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    .report-ffs-title {
      &__text {
        padding-left: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
        h1 {
          font-size: 24px;
        }
        h3 {
          font-size: 14px;
        }
      }
      &__icon {
        margin-bottom: 20px;
        width: 70%;
        padding: 15px;
        h3 {
          font-size: 14px;
        }
        .relationship-details__type {
          .relationship-types {
            font-size: 18px;
          }
          span.fs-18 {
            font-size: 16px;
          }
        }
      }
    }
    .report-ffs-list-items {
      .report-ffs-items {
        font-size: 14px;
        &__title {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .communication-tool-title {
    .communication-tool-title__icon {
      width: 16px;
      height: 16px;
      // margin-right: 10px;
      // .communication-tool-title__icon-horizontal {
      //   height: 6px;
      // }

      // .communication-tool-title__icon-vertical {
      //   width: 6px;
      // }
    }

    .communication-tool-title__text {
      font-size: 20px;
    }
  }

  .communication-tool-container {
    width: 100%;
    .communication-tool-btn {
      .btn__primary {
        font-size: 16px;
      }
    }
  }
}
