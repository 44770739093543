/*Margin and Padding helpers*/
/*xs*/
.mt-xs {
  &-5 {
    margin-top: 5px;
  }

  &-10 {
    margin-top: 10px;
  }

  &-12 {
    margin-top: 12px;
  }

  &-15 {
    margin-top: 15px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-25 {
    margin-top: 25px;
  }

  &-30 {
    margin-top: 30px;
  }

  &-35 {
    margin-top: 35px;
  }

  &-40 {
    margin-top: 40px;
  }

  &-50 {
    margin-top: 50px;
  }

  &-55 {
    margin-top: 55px;
  }

  &-85 {
    margin-top: 85px;
  }

  &-90 {
    margin-top: 90px;
  }

  &-100 {
    margin-top: 100px;
  }
}

.mr-xs {
  &-10 {
    margin-right: 10px;
  }

  &-15 {
    margin-right: 15px;
  }

  &-20 {
    margin-right: 20px;
  }

  &-25 {
    margin-right: 25px;
  }

  &-35 {
    margin-right: 35px;
  }

  &-40 {
    margin-right: 40px;
  }
}

.ml-xs {
  &-5 {
    margin-left: 5px;
  }

  &-10 {
    margin-left: 10px;
  }
  &-15 {
    margin-left: 15px;
  }
  &-20 {
    margin-left: 20px;
  }

  &-30 {
    margin-left: 30px;
  }
}

.mb-xs {
  &-5 {
    margin-bottom: 5px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-50 {
    margin-bottom: 50px;
  }

  &-70 {
    margin-bottom: 70px;
  }

  &-100 {
    margin-bottom: 100px;
  }

  &-150 {
    margin-bottom: 150px;
  }

  &-200 {
    margin-bottom: 200px;
  }

  &-initial {
    margin-bottom: initial;
  }
}

.my-xs {
  &-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.mx-xs {
  &-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  &-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  &-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  &-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  &-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  &-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  &-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  &-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  &-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  &-120 {
    margin-left: 120px;
    margin-right: 120px;
  }

  &-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

.p-xs {
  &-10 {
    padding: 10px;
  }

  &-15 {
    padding: 15px;
  }

  &-20 {
    padding: 20px;
  }
}

.pt-xs {
  &-20 {
    padding-top: 20px;
  }

  &-25 {
    padding-top: 25px;
  }

  &-50 {
    padding-top: 50px;
  }

  &-64 {
    padding-top: 64px;
  }

  &-80 {
    padding-top: 80px;
  }
}

.pr-xs {
  &-20 {
    padding-right: 20px;
  }
}
.pb-xs {
  &-12 {
    padding-bottom: 12px;
  }

  &-20 {
    padding-bottom: 20px;
  }

  &-30 {
    padding-bottom: 30px;
  }

  &-70 {
    padding-bottom: 70px;
  }
  &-150 {
    padding-bottom: 150px;
  }
}

.pl-xs {
  &-20 {
    padding-left: 20px;
  }
}
.px-xs {
  &-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  &-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  &-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  &-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  &-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  &-auto {
    padding-left: auto;
    padding-right: auto;
  }
}

.py-xs {
  &-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/*md - min-width: 992px*/
@media (min-width: 992px) {
  .p-md {
    &-15 {
      padding: 15px;
    }

    &-25 {
      padding: 25px;
    }

    &-30 {
      padding: 30px;
    }

    &-40 {
      padding: 40px;
    }

    &-50 {
      padding: 50px;
    }
  }

  .pt-md {
    &-60 {
      padding-top: 60px;
    }

    &-70 {
      padding-top: 70px;
    }

    &-100 {
      padding-top: 100px;
    }

    &-130 {
      padding-top: 130px;
    }
  }

  .pl-md {
    &-20 {
      padding-left: 20px;
    }

    &-30 {
      padding-left: 30px;
    }

    &-50 {
      padding-left: 50px;
    }

    &-70 {
      padding-left: 70px;
    }
    &-initial {
      padding-left: initial;
    }
  }

  .pr-md {
    &-20 {
      padding-right: 20px;
    }

    &-30 {
      padding-right: 30px;
    }

    &-50 {
      padding-right: 50px;
    }
  }

  .pb-md {
    &-20 {
      padding-bottom: 20px;
    }

    &-50 {
      padding-bottom: 50px;
    }
  }

  .px-md {
    &-20 {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-25 {
      padding-left: 25px;
      padding-right: 25px;
    }

    &-30 {
      padding-left: 30px;
      padding-right: 30px;
    }

    &-40 {
      padding-left: 40px;
      padding-right: 40px;
    }

    &-50 {
      padding-left: 50px;
      padding-right: 50px;
    }

    &-60 {
      padding-left: 60px;
      padding-right: 60px;
    }

    &-65 {
      padding-left: 65px;
      padding-right: 65px;
    }

    &-70 {
      padding-left: 70px;
      padding-right: 70px;
    }

    &-100 {
      padding-left: 100px;
      padding-right: 100px;
    }

    &-110 {
      padding-left: 110px;
      padding-right: 110px;
    }

    &-115 {
      padding-left: 115px;
      padding-right: 115px;
    }

    &-120 {
      padding-left: 120px;
      padding-right: 120px;
    }

    &-136 {
      padding-left: 136px;
      padding-right: 136px;
    }
  }

  .py-md {
    &-10 {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-15 {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-20 {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-25 {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &-30 {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &-40 {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-50 {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &-60 {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &-65 {
      padding-top: 65px;
      padding-bottom: 65px;
    }

    &-100 {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &-200 {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }

  .mt-md {
    &-0 {
      margin-top: 0px;
    }

    &-10 {
      margin-top: 10px;
    }

    &-15 {
      margin-top: 15px;
    }

    &-20 {
      margin-top: 20px;
    }

    &-30 {
      margin-top: 30px;
    }

    &-35 {
      margin-top: 35px;
    }

    &-40 {
      margin-top: 40px;
    }

    &-45 {
      margin-top: 45px;
    }

    &-50 {
      margin-top: 50px;
    }

    &-60 {
      margin-top: 60px;
    }

    &-65 {
      margin-top: 65px;
    }

    &-70 {
      margin-top: 70px;
    }

    &-80 {
      margin-top: 80px;
    }

    &-90 {
      margin-top: 90px;
    }

    &-100 {
      margin-top: 100px;
    }

    &-125 {
      margin-top: 125px;
    }

    &-initial {
      margin-top: initial;
    }
  }

  .mr-md {
    &-10 {
      margin-right: 10px;
    }

    &-15 {
      margin-right: 15px;
    }

    &-20 {
      margin-right: 20px;
    }

    &-30 {
      margin-right: 30px;
    }

    &-40 {
      margin-right: 40px;
    }

    &-50 {
      margin-right: 50px;
    }

    &-60 {
      margin-right: 60px;
    }
    &-135 {
      margin-right: 135px;
    }
  }

  .mb-md {
    &-10 {
      margin-bottom: 10px;
    }

    &-20 {
      margin-bottom: 20px;
    }

    &-40 {
      margin-bottom: 40px;
    }

    &-50 {
      margin-bottom: 50px;
    }

    &-55 {
      margin-bottom: 55px;
    }

    &-80 {
      margin-bottom: 80px;
    }

    &-85 {
      margin-bottom: 85px;
    }

    &-100 {
      margin-bottom: 100px;
    }

    &-150 {
      margin-bottom: 150px;
    }

    &-initial {
      margin-bottom: initial;
    }
  }

  .ml-md {
    &-0 {
      margin-left: 0px;
    }

    &-10 {
      margin-left: 10px;
    }

    &-15 {
      margin-left: 15px;
    }

    &-20 {
      margin-left: 20px;
    }

    &-30 {
      margin-left: 30px;
    }

    &-50 {
      margin-left: 50px;
    }
    &-60 {
      margin-left: 60px;
    }
  }

  .mx-md {
    &-0 {
      margin-left: 0px;
      margin-right: 0px;
    }

    &-25 {
      margin-left: 25px;
      margin-right: 25px;
    }

    &-30 {
      margin-left: 30px;
      margin-right: 30px;
    }

    &-55 {
      margin-left: 55px;
      margin-right: 55px;
    }

    &-85 {
      margin-left: 85px;
      margin-right: 85px;
    }

    &-90 {
      margin-left: 90px;
      margin-right: 90px;
    }

    &-200 {
      margin-left: 200px;
      margin-right: 200px;
    }

    &-280 {
      margin-left: 280px;
      margin-right: 280px;
    }

    &-410 {
      margin-left: 410px;
      margin-right: 410px;
    }

    &-480 {
      margin-left: 480px;
      margin-right: 480px;
    }

    &-auto {
      margin-left: auto;
      margin-right: auto;
    }

    &-initial {
      margin-left: initial;
      margin-right: initial;
    }
  }

  .my-md {
    &-20 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-30 {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &-60 {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &-80 {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    &-100 {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    &-115 {
      margin-top: 115px;
      margin-bottom: 115px;
    }

    &-auto {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

/*lg - min-width: 1200px*/
