@import "variables";

.btn {
  &-orange {
    &-base {
      color: $white-base;
      background-color: $orange-primary;
      border-radius: 6px;
    }
    &-base:hover {
      color: $white-base;
    }
  }

  &__primary {
    color: $white-base;
    border-radius: 6px;
    &-orange {
      background-color: $orange-primary;
    }
    &-orange:hover {
      color: $white-base;
    }
  }

  &--has-shadow {
    &-orange {
      box-shadow: 0px 8px $orange-primary-01;
    }
  }

  &--has-border {
    &-orange {
      border: 2px solid $orange-primary-01;
    }
  }
}
